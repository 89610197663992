import React from 'react';

const Person1 = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="17" height="15" viewBox="0 0 17 15">
        <g fill="none" fillRule="evenodd">
            <g>
                <path
                    fill="#FFF"
                    d="M0 7c0-3.865 3.358-7 7.5-7C11.643 0 15 3.135 15 7"
                    transform="translate(1 1) translate(0 7)"
                />
                <path
                    stroke="#233647"
                    strokeLinejoin="round"
                    strokeWidth="1.437"
                    d="M0 7c0-3.865 3.358-7 7.5-7C11.643 0 15 3.135 15 7"
                    transform="translate(1 1) translate(0 7)"
                />
            </g>
            <g>
                <path
                    fill="#FFF"
                    d="M0 3.377C0 1.512 1.512 0 3.377 0c1.865 0 3.377 1.512 3.377 3.377 0 1.865-1.512 3.377-3.377 3.377C1.512 6.754 0 5.242 0 3.377"
                    transform="translate(1 1) translate(4)"
                />
                <path
                    stroke="#233647"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="1.437"
                    d="M0 3.377C0 1.512 1.512 0 3.377 0c1.865 0 3.377 1.512 3.377 3.377 0 1.865-1.512 3.377-3.377 3.377C1.512 6.754 0 5.242 0 3.377z"
                    transform="translate(1 1) translate(4)"
                />
            </g>
        </g>
    </svg>
);

export default Person1;

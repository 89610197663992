import React from 'react';

const GasIcon2 = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="22" viewBox="0 0 18 22">
        <g fill="#051238" fillRule="evenodd">
            <path d="M8.99 0c1.269 1.327 2.445 2.486 3.54 3.714 1.865 2.091 3.603 4.283 4.698 6.888 1.143 2.725 1.084 5.388-.633 7.887-1.004 1.464-2.421 2.402-4.114 2.947-2.3.739-4.61.758-6.902.02C1.143 20.027-.974 15.816.43 11.295c.792-2.553 2.268-4.717 4.027-6.682C5.864 3.042 7.413 1.595 8.99 0zm1.637 12.31c-.403.696-.7 1.583-1.303 2.155-.776.735-1.664.224-2.614-.449-.412 1.474-.489 2.26-.41 3.507.092 1.49 1.128 2.64 2.564 2.714 1.414.074 2.801-1.073 2.866-2.508.08-1.829-.333-3.755-1.103-5.418zm-1.6-9.682c-2.086 1.435-5.203 5.339-6.39 7.953-.675 1.492-1.044 3.017-.736 4.667.308 1.662 1.583 3.412 2.843 3.854-.633-3.004.226-5.74 1.601-8.462-.036.072.15.343.183.403.114.22.235.438.356.654.239.42.62.902 1.098 1.069.434.152.627-.255.713-.608.325-1.339.327-2.57.305-3.938-.003-.182-.05-.772-.098-1.363 3.533 3.504 4.95 7.552 4.444 12.238 2.07-.916 3.414-3.872 2.667-6.375-1.225-4.106-4.05-7.145-6.985-10.092z" />
        </g>
    </svg>
);

export default GasIcon2;

import React from 'react';

const House4 = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="23" height="18" viewBox="0 0 23 18">
        <g fill="#051238">
            <path d="M17.779 6.818c.456 0 .834.343.899.79l.01.137v9.223c0 .466-.337.85-.775.917l-.134.01H5.22c-.456 0-.834-.343-.899-.79l-.01-.137V7.745c0-.512.407-.927.91-.927.455 0 .833.343.898.79l.01.137-.001 8.295h10.74l.001-8.295c0-.465.337-.85.775-.917l.134-.01z" />
            <path d="M10.976.098c.223-.108.479-.127.713-.056l.138.053 10.623 5.02c.484.228.686.797.453 1.27-.212.43-.711.633-1.164.495l-.133-.052L11.408 2.01 1.4 6.825c-.438.211-.96.07-1.228-.312L.1 6.39c-.215-.429-.07-.94.32-1.202l.123-.072L10.976.097z" />
        </g>
    </svg>
);

export default House4;

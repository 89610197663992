import React from 'react';

const ElectricityIcon2 = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22">
        <g fill="#051238" fillRule="evenodd">
            <path d="M11 0C4.925 0 0 4.925 0 11s4.925 11 11 11 11-4.925 11-11S17.075 0 11 0zm0 2c4.97 0 9 4.03 9 9s-4.03 9-9 9-9-4.03-9-9 4.03-9 9-9z" />
            <path d="M11.434 9.568l.879-4.832c.037-.203-.218-.322-.345-.161-1.289 1.63-4.77 6.059-5.757 7.293-.104.13-.013.323.152.323l2.992-.001c.122 0 .213.112.191.234-.291 1.594-.648 3.56-.88 4.84-.037.202.216.322.344.162 1.296-1.624 4.824-6.071 5.78-7.305.103-.13.01-.32-.153-.32l-3.012.001c-.122 0-.214-.112-.191-.234" />
        </g>
    </svg>
);

export default ElectricityIcon2;

import React from 'react';

const HouseIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="19" height="15" viewBox="0 0 19 15">
        <g fill="currentColor">
            <path
                d="M13.605 5.455c.349 0 .638.274.687.631l.008.11v7.379c0 .372-.257.68-.593.733l-.102.008h-9.61c-.349 0-.638-.274-.687-.632l-.008-.11V6.197c0-.41.311-.741.695-.741h9.61z"
                transform="translate(.8 .4)"
            />
            <path
                d="M8.4.078c.17-.086.365-.1.545-.045l.105.043 8.13 4.015c.37.183.524.638.346 1.017-.162.344-.545.507-.89.396l-.103-.041L8.73 1.608 1.072 5.46c-.335.169-.734.056-.94-.25l-.056-.097c-.164-.343-.054-.751.245-.962l.095-.057L8.399.078z"
                transform="translate(.8 .4)"
            />
        </g>
    </svg>
);

export default HouseIcon;

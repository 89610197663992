import React from 'react';

const Attention = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="28px" height="28px" viewBox="0 0 28 28" version="1.1">
        <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g id="form-Card-3-Copy" transform="translate(-235.000000, -203.000000)">
                <g id="Group-48-Copy" transform="translate(235.000000, 203.000000)">
                    <circle id="Oval" fill="#233647" cx="14" cy="14" r="14" />
                    <g id="Group-47" transform="translate(12.000000, 6.000000)" fill="#FFFFFF" fillRule="nonzero">
                        <polygon id="Path-2" points="4 0 4 10 0 10 0 0" />
                        <polygon id="Path-2-Copy" points="4 13 4 16 0 16 0 13" />
                    </g>
                </g>
            </g>
        </g>
    </svg>
);

export default Attention;

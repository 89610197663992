import React from 'react';

const Email = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 59 60">
        <defs>
            <polygon id="path-1" points="0 0.773383686 58.0057704 0.773383686 58.0057704 58.779426 0 58.779426" />
        </defs>
        <g id="FWF-PAGE" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g id="FWF-PAGE-MOBILE-PRE-VALIDATION" transform="translate(-10.000000, -705.000000)">
                <g id="Group-11" transform="translate(-40.000000, 55.000000)">
                    <g id="Group-5" transform="translate(50.000000, 591.000000)">
                        <g id="Group-38" transform="translate(0.000000, 59.000000)">
                            <g id="Mail" transform="translate(0.000000, 0.133233)">
                                <polygon
                                    id="Fill-17"
                                    fill="#AFB3BF"
                                    points="11.6846828 19.8447734 11.6846828 39.4127492 21.9028097 29.0387311"
                                />
                                <g id="mail" transform="translate(-0.000000, -0.000000)">
                                    <mask id="mask-2" fill="white">
                                        <use xlinkHref="#path-1" />
                                    </mask>
                                    <g id="Clip-20" />
                                    <path
                                        d="M49.1997281,40.3942296 C49.1997281,42.9365257 47.1314502,45.0038973 44.5891541,45.0038973 L13.4163444,45.0038973 C10.8740483,45.0038973 8.80667674,42.9365257 8.80667674,40.3942296 L8.80667674,19.1585801 C8.80667674,16.6171903 10.8740483,14.5489124 13.4163444,14.5489124 L44.5891541,14.5489124 C47.1314502,14.5489124 49.1997281,16.6171903 49.1997281,19.1585801 L49.1997281,40.3942296 Z M45.0849245,0.773383686 L12.920574,0.773383686 C5.78673716,0.773383686 -0.000271903323,6.54498489 -0.000271903323,13.6788218 L-0.000271903323,45.8585801 C-0.000271903323,52.9933233 5.78673716,58.779426 12.920574,58.779426 L45.0849245,58.779426 C52.2196677,58.779426 58.0057704,52.9933233 58.0057704,45.8585801 L58.0057704,13.6788218 C58.0057704,6.54498489 52.2196677,0.773383686 45.0849245,0.773383686 L45.0849245,0.773383686 Z"
                                        id="Fill-19"
                                        fill="#AFB3BF"
                                        mask="url(#mask-2)"
                                    />
                                </g>
                                <path
                                    d="M32.1367069,32.6070997 C31.1805136,33.3856495 30.0783988,33.7726586 28.9835347,33.7726586 C27.9067976,33.7726586 26.8373112,33.3983384 25.9246224,32.655136 L24.044864,30.9648036 L13.1116314,42.0648036 C13.2140483,42.0838369 13.3083082,42.126435 13.4161631,42.126435 L44.5889728,42.126435 C44.6950151,42.126435 44.7874622,42.0838369 44.8880665,42.0666163 L33.9629909,30.9638973 L32.1367069,32.6070997 Z"
                                    id="Fill-22"
                                    fill="#AFB3BF"
                                />
                                <path
                                    d="M30.2647432,30.4218127 L44.6792447,17.4456798 C44.6475227,17.4438671 44.6212387,17.4266465 44.5895166,17.4266465 L13.4158006,17.4266465 C13.3822659,17.4266465 13.3541692,17.4447734 13.3206344,17.4465861 L27.7940483,30.4698489 C28.532719,31.0662236 29.4753172,31.0644109 30.2647432,30.4218127"
                                    id="Fill-24"
                                    fill="#AFB3BF"
                                />
                                <polygon
                                    id="Fill-26"
                                    fill="#AFB3BF"
                                    points="46.3222659 39.4197281 46.3222659 19.8381571 36.104139 29.0357402"
                                />
                            </g>
                        </g>
                    </g>
                </g>
            </g>
        </g>
    </svg>
);

export default Email;

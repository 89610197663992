import React from 'react';

const DropdownDoubleArrow = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="6" height="17" viewBox="0 0 6 17">
        <g fill="#233647" fillRule="evenodd">
            <path d="M2.59 0L5.18 6.052 0 6.052zM2.59 16.506L0 10.455 5.18 10.455z" transform="translate(.315)" />
        </g>
    </svg>
);

export default DropdownDoubleArrow;

import React from 'react';

const CircleTick = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="28px" height="28px" viewBox="0 0 28 28" version="1.1">
        <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g id="Slider" transform="translate(-1213.000000, -255.000000)">
                <g id="Group-6" transform="translate(608.000000, 216.000000)">
                    <g id="Group-5" transform="translate(605.000000, 39.000000)">
                        <circle id="Oval" fill="#FFD471" cx="14" cy="14" r="14" />
                        <polygon
                            id="Path"
                            fill="#FFFFFF"
                            fillRule="nonzero"
                            points="20.2840978 6.83228688 23 9.31513338 12.3037835 20.8322869 6 14.597793 8.60724466 12.0028216 12.1897861 15.5459088"
                        />
                    </g>
                </g>
            </g>
        </g>
    </svg>
);

export default CircleTick;

import React from 'react';

const Settings = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="64" height="64" viewBox="0 0 64 64">
        <g fill="none" fillRule="evenodd">
            <path
                fill="#233647"
                d="M4.88 31.772L7.703 31.772 7.703 1.189 4.88 1.189z"
                transform="translate(13.772 15.532)"
            />
            <path
                fill="#FFF"
                d="M2.415 12.76c0-2.145 1.738-3.883 3.882-3.883 2.146 0 3.883 1.738 3.883 3.883 0 2.144-1.737 3.882-3.883 3.882-2.144 0-3.882-1.738-3.882-3.882"
                transform="translate(13.772 15.532)"
            />
            <path
                fill="#233647"
                fillRule="nonzero"
                d="M6.297 7.398c-2.96 0-5.36 2.4-5.36 5.362 0 2.961 2.4 5.36 5.36 5.36 2.962 0 5.362-2.399 5.362-5.36 0-2.962-2.4-5.362-5.362-5.362zm0 2.958c1.329 0 2.404 1.076 2.404 2.404 0 1.327-1.075 2.403-2.404 2.403-1.327 0-2.403-1.076-2.403-2.403 0-1.328 1.076-2.404 2.403-2.404z"
                transform="translate(13.772 15.532)"
            />
            <path
                fill="#233647"
                d="M16.786 31.772L19.609 31.772 19.609 1.189 16.786 1.189z"
                transform="translate(13.772 15.532)"
            />
            <path
                fill="#FFF"
                d="M14.322 5.511c0-2.144 1.739-3.881 3.883-3.881s3.882 1.737 3.882 3.881c0 2.144-1.738 3.884-3.882 3.884s-3.883-1.74-3.883-3.884"
                transform="translate(13.772 15.532)"
            />
            <path
                fill="#233647"
                fillRule="nonzero"
                d="M18.205.15c-2.961 0-5.362 2.4-5.362 5.361s2.401 5.363 5.362 5.363c2.96 0 5.36-2.402 5.36-5.363 0-2.96-2.4-5.36-5.36-5.36zm0 2.959c1.327 0 2.403 1.075 2.403 2.402 0 1.328-1.076 2.405-2.403 2.405S15.8 6.839 15.8 5.51c0-1.326 1.076-2.402 2.404-2.402z"
                transform="translate(13.772 15.532)"
            />
            <path
                fill="#233647"
                d="M28.694 31.772L31.515 31.772 31.515 1.189 28.694 1.189z"
                transform="translate(13.772 15.532)"
            />
            <path
                fill="#FFF"
                d="M26.23 22.272c0-2.144 1.737-3.883 3.881-3.883 2.145 0 3.883 1.74 3.883 3.883 0 2.144-1.738 3.883-3.883 3.883-2.144 0-3.882-1.739-3.882-3.883"
                transform="translate(13.772 15.532)"
            />
            <path
                fill="#233647"
                fillRule="nonzero"
                d="M30.111 16.91c-2.96 0-5.36 2.401-5.36 5.362 0 2.961 2.4 5.362 5.36 5.362 2.962 0 5.362-2.4 5.362-5.362 0-2.96-2.4-5.362-5.362-5.362zm0 2.958c1.328 0 2.404 1.077 2.404 2.404 0 1.328-1.076 2.405-2.404 2.405-1.327 0-2.403-1.077-2.403-2.405 0-1.327 1.076-2.404 2.403-2.404z"
                transform="translate(13.772 15.532)"
            />
        </g>
    </svg>
);

export default Settings;

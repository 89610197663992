import React from 'react';

const Facebook = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 60 59" version="1.1">
        <g id="FWF-PAGE" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g id="FWF-PAGE-MOBILE-PRE-VALIDATION" transform="translate(-44.000000, -793.000000)">
                <g id="Group-11" transform="translate(-40.000000, 55.000000)">
                    <g id="Group-5" transform="translate(50.000000, 591.000000)">
                        <g id="Group-38" transform="translate(0.000000, 59.000000)">
                            <g id="Group-31" transform="translate(0.000000, 0.133233)">
                                <g id="fb" transform="translate(34.441088, 87.915408)">
                                    <path
                                        d="M45.5792447,0.244803625 L13.5435952,0.244803625 C6.43966767,0.244803625 0.679848943,6.00462236 0.679848943,13.1085498 L0.679848943,45.1432931 C0.679848943,52.2472205 6.43966767,58.0061329 13.5435952,58.0061329 L45.5792447,58.0061329 C52.6822659,58.0061329 58.4411782,52.2472205 58.4411782,45.1432931 L58.4411782,13.1085498 C58.4411782,6.00462236 52.6822659,0.244803625 45.5792447,0.244803625 L45.5792447,0.244803625 Z"
                                        id="Path"
                                        fill="#3B5998"
                                    />
                                    <path
                                        d="M39.7451057,15.5910272 L34.8136858,15.5910272 C33.8393656,15.5910272 32.8541692,16.6124773 32.8541692,17.3674622 L32.8541692,22.4321148 L39.7324169,22.4321148 C39.4550755,26.3185196 38.893142,29.8722961 38.893142,29.8722961 L32.8179154,29.8722961 L32.8179154,50.3729003 L23.7771299,50.3729003 L23.7771299,29.8722961 L19.3768278,29.8722961 L19.3768278,22.4620242 L23.7771299,22.4620242 L23.7771299,16.4058308 C23.7771299,15.2946526 23.5541692,7.8789426 33.0417825,7.8789426 L39.7451057,7.8789426 L39.7451057,15.5910272 Z"
                                        id="Path"
                                        fill="#FFFFFF"
                                    />
                                </g>
                            </g>
                        </g>
                    </g>
                </g>
            </g>
        </g>
    </svg>
);

export default Facebook;

import React from 'react';

const SearchIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="21" height="19" viewBox="0 0 21 19">
        <path
            fill="#FFF"
            fillRule="evenodd"
            d="M8.278 0c4.564 0 8.278 3.756 8.278 8.373 0 1.686-.5 3.254-1.35 4.57l5.256 3.73a1.29 1.29 0 0 1 .308 1.782 1.259 1.259 0 0 1-1.762.313l-5.506-3.908a8.176 8.176 0 0 1-5.224 1.887C3.713 16.747 0 12.99 0 8.373 0 3.756 3.713 0 8.278 0zm0 2.56c-3.17 0-5.748 2.607-5.748 5.813 0 3.206 2.578 5.814 5.748 5.814a5.698 5.698 0 0 0 4.152-1.805c.033-.039.064-.079.101-.112a5.821 5.821 0 0 0 1.494-3.897c0-3.206-2.578-5.814-5.747-5.814z"
        />
    </svg>
);

export default SearchIcon;

import React from 'react';

const NT = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="17px" height="10px" viewBox="0 0 17 10" version="1.1">
        <g
            stroke="none"
            strokeWidth="1"
            fill="none"
            fillRule="evenodd"
            fontFamily="Gilroy-Semibold, Gilroy"
            fontSize="14"
            fontWeight="500"
            letterSpacing="-0.00416675"
        >
            <g id="icon/NT" fill="currentColor">
                <text id="NT">
                    <tspan x="0.49916675" y="11">
                        NT
                    </tspan>
                </text>
            </g>
        </g>
    </svg>
);

export default NT;

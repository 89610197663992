import React from 'react';

const House1 = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="12" viewBox="0 0 14 12">
        <g fill="#051238">
            <path d="M10.822 4.546c.278 0 .508.228.547.526l.006.091v6.15c0 .31-.205.566-.471.61l-.082.007H3.178c-.278 0-.508-.228-.547-.526l-.006-.092V5.163c0-.34.248-.617.553-.617.278 0 .508.228.547.526l.006.091v5.53h6.537v-5.53c0-.31.206-.567.472-.61l.082-.007z" />
            <path d="M6.681.065c.136-.072.291-.084.434-.037L7.2.063l6.466 3.347c.295.152.418.531.276.847-.129.286-.433.422-.708.33l-.082-.035L6.944 1.34.853 4.55c-.267.14-.584.046-.748-.208L.061 4.26c-.131-.286-.043-.626.194-.802l.076-.047L6.68.065z" />
        </g>
    </svg>
);

export default House1;

import React from 'react';

const ChargingBattery = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="33" height="17" viewBox="0 0 33 17">
        <g fill="none" fillRule="evenodd">
            <path
                stroke="#051238"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1.572"
                d="M25.636 15.828H2.93c-1.184 0-2.144-.96-2.144-2.144V2.93c0-1.184.96-2.144 2.144-2.144h22.706c1.184 0 2.144.96 2.144 2.144v10.754c0 1.184-.96 2.144-2.144 2.144zM30.118 4.794h.79c.553 0 1.002.448 1.002 1.002v5.022c0 .553-.45 1.002-1.002 1.002h-.79"
            />
            <path
                fill="#051238"
                d="M10.399 12.9h-5.67c-.522 0-.945-.534-.945-1.193V4.84c0-.66.423-1.193.945-1.193h6.899c.606 0 1.056.71.922 1.457l-1.23 6.866c-.097.544-.48.93-.921.93"
            />
        </g>
    </svg>
);

export default ChargingBattery;

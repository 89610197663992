import React from 'react';

const House5 = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="27" height="22" viewBox="0 0 27 22">
        <g fill="#051238">
            <path d="M20.87 8.333c.536 0 .98.42 1.056.966l.012.167V20.74c0 .569-.395 1.04-.91 1.12l-.157.013H6.129c-.535 0-.979-.42-1.055-.965l-.011-.168V9.466c0-.625.477-1.133 1.066-1.133.536 0 .979.42 1.055.966l.012.167-.002 10.139h12.61V9.466c0-.569.395-1.04.91-1.12l.157-.013z" />
            <path d="M12.885.12c.262-.132.562-.155.837-.069l.162.065 12.47 6.135c.568.279.806.974.532 1.553-.249.526-.835.774-1.366.605l-.157-.063-11.971-5.89L1.644 8.342C1.13 8.6.518 8.427.203 7.96L.117 7.81c-.252-.524-.083-1.148.375-1.47l.146-.087L12.885.12z" />
        </g>
    </svg>
);

export default House5;

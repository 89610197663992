import React from 'react';

const LocationIcon = () => (
    <svg
        aria-hidden
        xmlns="http://www.w3.org/2000/svg"
        width="0.9em"
        height="1.1em"
        viewBox="0 0 13 18"
        focusable="false"
    >
        <path
            fill="currentColor"
            fillRule="evenodd"
            d="M3.842 6.321c0-1.494 1.19-2.705 2.658-2.705s2.659 1.21 2.659 2.705S7.969 9.027 6.5 9.027c-1.467 0-2.658-1.211-2.658-2.706zM0 6.623a6.75 6.75 0 0 0 .381 2.235c.593 1.573 1.786 2.978 2.874 4.549.673.972 3.268 4.563 3.253 4.593-.015-.03 2.576-3.625 3.247-4.597 1.086-1.575 2.277-2.982 2.868-4.556.056-.15.1-.308.143-.462A6.763 6.763 0 0 0 13 6.612C12.997 2.956 10.084-.003 6.494 0 2.905.003-.003 2.968 0 6.623z"
        />
    </svg>
);

export default LocationIcon;

import React from 'react';

const ElectricityIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="20" viewBox="0 0 14 20">
        <path
            fill="currentColor"
            d="M8.437 7.797L9.789.363c.057-.313-.335-.495-.53-.248C7.276 2.623 1.92 9.437.4 11.335c-.16.2-.019.497.235.497l4.602-.002c.189 0 .329.172.294.36-.448 2.452-.996 5.479-1.354 7.447-.057.31.333.495.53.249 1.994-2.498 7.42-9.34 8.893-11.238.157-.201.015-.493-.237-.493-1.527 0-3.134 0-4.633.002-.188 0-.329-.172-.294-.36"
        />
    </svg>
);

export default ElectricityIcon;

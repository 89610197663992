import React from 'react';

const Person5 = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="33" height="18" viewBox="0 0 33 18">
        <g fill="none" fillRule="evenodd">
            <g>
                <g>
                    <path
                        fill="#FFF"
                        d="M0 7c0-3.865 3.358-7 7.5-7C11.643 0 15 3.135 15 7"
                        transform="translate(1 4) translate(0 7)"
                    />
                    <path
                        stroke="#233647"
                        strokeLinejoin="round"
                        strokeWidth="1.437"
                        d="M0 7c0-3.865 3.358-7 7.5-7C11.643 0 15 3.135 15 7"
                        transform="translate(1 4) translate(0 7)"
                    />
                </g>
                <g>
                    <path
                        fill="#FFF"
                        d="M0 3.377C0 1.512 1.512 0 3.377 0c1.865 0 3.377 1.512 3.377 3.377 0 1.865-1.512 3.377-3.377 3.377C1.512 6.754 0 5.242 0 3.377"
                        transform="translate(1 4) translate(4)"
                    />
                    <path
                        stroke="#233647"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="1.437"
                        d="M0 3.377C0 1.512 1.512 0 3.377 0c1.865 0 3.377 1.512 3.377 3.377 0 1.865-1.512 3.377-3.377 3.377C1.512 6.754 0 5.242 0 3.377z"
                        transform="translate(1 4) translate(4)"
                    />
                </g>
            </g>
            <g>
                <g>
                    <path
                        fill="#FFF"
                        d="M0 7c0-3.865 3.358-7 7.5-7C11.643 0 15 3.135 15 7"
                        transform="translate(5 4) translate(0 7)"
                    />
                    <path
                        stroke="#233647"
                        strokeLinejoin="round"
                        strokeWidth="1.437"
                        d="M0 7c0-3.865 3.358-7 7.5-7C11.643 0 15 3.135 15 7"
                        transform="translate(5 4) translate(0 7)"
                    />
                </g>
                <g>
                    <path
                        fill="#FFF"
                        d="M0 3.377C0 1.512 1.512 0 3.377 0c1.865 0 3.377 1.512 3.377 3.377 0 1.865-1.512 3.377-3.377 3.377C1.512 6.754 0 5.242 0 3.377"
                        transform="translate(5 4) translate(4)"
                    />
                    <path
                        stroke="#233647"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="1.437"
                        d="M0 3.377C0 1.512 1.512 0 3.377 0c1.865 0 3.377 1.512 3.377 3.377 0 1.865-1.512 3.377-3.377 3.377C1.512 6.754 0 5.242 0 3.377z"
                        transform="translate(5 4) translate(4)"
                    />
                </g>
            </g>
            <g>
                <g>
                    <path
                        fill="#FFF"
                        d="M0 7c0-3.865 3.358-7 7.5-7C11.643 0 15 3.135 15 7"
                        transform="translate(9 4) translate(0 7)"
                    />
                    <path
                        stroke="#233647"
                        strokeLinejoin="round"
                        strokeWidth="1.437"
                        d="M0 7c0-3.865 3.358-7 7.5-7C11.643 0 15 3.135 15 7"
                        transform="translate(9 4) translate(0 7)"
                    />
                </g>
                <g>
                    <path
                        fill="#FFF"
                        d="M0 3.377C0 1.512 1.512 0 3.377 0c1.865 0 3.377 1.512 3.377 3.377 0 1.865-1.512 3.377-3.377 3.377C1.512 6.754 0 5.242 0 3.377"
                        transform="translate(9 4) translate(4)"
                    />
                    <path
                        stroke="#233647"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="1.437"
                        d="M0 3.377C0 1.512 1.512 0 3.377 0c1.865 0 3.377 1.512 3.377 3.377 0 1.865-1.512 3.377-3.377 3.377C1.512 6.754 0 5.242 0 3.377z"
                        transform="translate(9 4) translate(4)"
                    />
                </g>
            </g>
            <g>
                <g>
                    <path
                        fill="#FFF"
                        d="M0 7c0-3.865 3.358-7 7.5-7C11.643 0 15 3.135 15 7"
                        transform="translate(13 4) translate(0 7)"
                    />
                    <path
                        stroke="#233647"
                        strokeLinejoin="round"
                        strokeWidth="1.437"
                        d="M0 7c0-3.865 3.358-7 7.5-7C11.643 0 15 3.135 15 7"
                        transform="translate(13 4) translate(0 7)"
                    />
                </g>
                <g>
                    <path
                        fill="#FFF"
                        d="M0 3.377C0 1.512 1.512 0 3.377 0c1.865 0 3.377 1.512 3.377 3.377 0 1.865-1.512 3.377-3.377 3.377C1.512 6.754 0 5.242 0 3.377"
                        transform="translate(13 4) translate(4)"
                    />
                    <path
                        stroke="#233647"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="1.437"
                        d="M0 3.377C0 1.512 1.512 0 3.377 0c1.865 0 3.377 1.512 3.377 3.377 0 1.865-1.512 3.377-3.377 3.377C1.512 6.754 0 5.242 0 3.377z"
                        transform="translate(13 4) translate(4)"
                    />
                </g>
            </g>
            <path
                fill="#233647"
                fillRule="nonzero"
                d="M29.83 7.074L29.83 4.57 32.334 4.57 32.334 3.058 29.83 3.058 29.83 0.554 28.319 0.554 28.319 3.058 25.815 3.058 25.815 4.57 28.319 4.57 28.319 7.074z"
            />
        </g>
    </svg>
);

export default Person5;

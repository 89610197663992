import React from 'react';

const SMS = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 56 51" version="1.1">
        <g id="FWF-PAGE" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g id="FWF-PAGE-MOBILE-PRE-VALIDATION" transform="translate(-92.000000, -710.000000)">
                <g id="Group-11" transform="translate(-40.000000, 55.000000)">
                    <g id="Group-5" transform="translate(50.000000, 591.000000)">
                        <g id="Group-38" transform="translate(0.000000, 59.000000)">
                            <g id="Group-31" transform="translate(0.000000, 0.133233)">
                                <g id="SMS" transform="translate(83.540302, 7.153233)">
                                    <path
                                        d="M46.4329305,36.2564955 L21.9335347,36.2564955 L11.1462236,45.2456193 L11.1462236,36.2564955 L5.68912387,36.2564955 C2.54682779,36.2564955 9.9475983e-14,33.7087613 9.9475983e-14,30.5673716 L9.9475983e-14,5.68912387 C9.9475983e-14,2.54773414 2.54682779,-4.26325641e-14 5.68912387,-4.26325641e-14 L46.4329305,-4.26325641e-14 C49.5752266,-4.26325641e-14 52.1220544,2.54773414 52.1220544,5.68912387 L52.1220544,30.5673716 C52.1220544,33.7087613 49.5752266,36.2564955 46.4329305,36.2564955 Z"
                                        id="Stroke-9"
                                        stroke="#051238"
                                        strokeWidth="2.821"
                                        strokeLinecap="round"
                                    />
                                    <path
                                        d="M14.6631118,18.0004532 C16.0325982,18.2823263 17.813565,18.9367069 17.7945317,20.9433535 C17.7945317,21.8995468 17.4392447,22.65 16.7078248,23.1738671 C15.9954381,23.6805136 15.0954381,23.9424471 14.0259517,23.9424471 C12.0954381,23.9424471 10.782145,23.1929003 10.0887915,21.7119335 L12.1896979,20.5300604 C12.4697583,21.3367069 13.0697583,21.75 14.0259517,21.75 C14.8887915,21.75 15.3202115,21.4871601 15.3202115,20.9433535 C15.3202115,20.4367069 14.568852,20.1566465 13.613565,19.9110272 C12.2259517,19.5557402 10.4830514,18.9747734 10.4830514,17.0243202 C10.4830514,16.105287 10.8202115,15.3756798 11.4945317,14.8490937 C12.1896979,14.3070997 13.0316918,14.0433535 14.0440785,14.0433535 C15.5631118,14.0433535 16.8754985,14.7557402 17.6069184,16.0309668 L15.5440785,17.1557402 C15.2449849,16.5185801 14.7392447,16.1995468 14.0440785,16.1995468 C13.4259517,16.1995468 12.9573716,16.4995468 12.9573716,16.9681269 C12.9573716,17.4747734 13.7069184,17.7557402 14.6631118,18.0004532"
                                        id="Fill-11"
                                        fill="#051238"
                                    />
                                    <path
                                        d="M33.3529305,17.9065559 L33.3529305,23.6799698 L30.9338973,23.6799698 L30.9338973,18.075136 C30.9338973,16.9494562 30.3520242,16.2932628 29.3604834,16.2932628 C28.2538369,16.2932628 27.6157704,17.043716 27.6157704,18.4122961 L27.6157704,23.6799698 L25.1976435,23.6799698 L25.1976435,18.075136 C25.1976435,16.9494562 24.6157704,16.2932628 23.6224169,16.2932628 C22.5538369,16.2932628 21.859577,17.043716 21.859577,18.4122961 L21.859577,23.6799698 L19.4414502,23.6799698 L19.4414502,14.3065559 L21.859577,14.3065559 L21.859577,15.299003 C22.4215106,14.4561027 23.2852568,14.0428097 24.4662236,14.0428097 C25.6100302,14.0428097 26.4728701,14.4932628 27.0166767,15.3932628 C27.6348036,14.4932628 28.5719637,14.0428097 29.8091239,14.0428097 C31.9662236,14.0428097 33.3529305,15.5627492 33.3529305,17.9065559"
                                        id="Fill-13"
                                        fill="#051238"
                                    />
                                    <path
                                        d="M39.3450453,18.0004532 C40.7145317,18.2823263 42.4954985,18.9367069 42.4755589,20.9433535 C42.4755589,21.8995468 42.1202719,22.65 41.388852,23.1738671 C40.6764653,23.6805136 39.7764653,23.9424471 38.7078852,23.9424471 C36.7764653,23.9424471 35.4640785,23.1929003 34.7698187,21.7119335 L36.8707251,20.5300604 C37.1507855,21.3367069 37.7507855,21.75 38.7078852,21.75 C39.5698187,21.75 40.0012387,21.4871601 40.0012387,20.9433535 C40.0012387,20.4367069 39.2507855,20.1566465 38.2945921,19.9110272 C36.9078852,19.5557402 35.1640785,18.9747734 35.1640785,17.0243202 C35.1640785,16.105287 35.5012387,15.3756798 36.1764653,14.8490937 C36.8707251,14.3070997 37.7136254,14.0433535 38.7251057,14.0433535 C40.2450453,14.0433535 41.5565257,14.7557402 42.2879456,16.0309668 L40.2260121,17.1557402 C39.9260121,16.5185801 39.4202719,16.1995468 38.7251057,16.1995468 C38.1069789,16.1995468 37.6393051,16.4995468 37.6393051,16.9681269 C37.6393051,17.4747734 38.3879456,17.7557402 39.3450453,18.0004532"
                                        id="Fill-15"
                                        fill="#051238"
                                    />
                                </g>
                            </g>
                        </g>
                    </g>
                </g>
            </g>
        </g>
    </svg>
);

export default SMS;

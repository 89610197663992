import React from 'react';

const House3 = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="16" viewBox="0 0 20 16">
        <g fill="#051238">
            <path d="M15.46 6.06c.397 0 .725.306.781.703l.009.122v8.198c0 .414-.292.756-.673.815l-.117.009H4.54c-.397 0-.725-.305-.781-.702l-.009-.122V6.885c0-.455.354-.824.79-.824.397 0 .725.305.782.702l.008.122v7.373h9.339V6.885c0-.414.293-.757.674-.815l.117-.01z" />
            <path d="M9.544.087c.195-.096.417-.112.62-.05l.12.047 9.238 4.462c.42.203.597.709.394 1.13-.185.382-.619.563-1.012.44l-.117-.046L9.92 1.786l-8.702 4.28c-.38.188-.834.062-1.068-.277l-.063-.108C-.1 5.3.025 4.846.365 4.612l.108-.063L9.544.087z" />
        </g>
    </svg>
);

export default House3;

import React from 'react';

const HouseHold = () => (
    <svg width="21px" height="21px" viewBox="0 0 21 21" version="1.1" xmlns="http://www.w3.org/2000/svg">
        <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g transform="translate(-59.000000, -199.000000)">
                <g transform="translate(59.000000, 133.000000)">
                    <g transform="translate(0.000000, 66.000000)">
                        <g transform="translate(0.000000, 0.000000)">
                            <g transform="translate(0.000000, 0.000000)">
                                <g transform="translate(0.000000, 0.000000)" fill="currentColor" fillRule="nonzero">
                                    <path d="M15.2727273,5.96590909 C16.1952938,5.96590909 16.9431818,6.71379707 16.9431818,7.63636364 L16.9431818,7.63636364 L16.9431818,16.2272727 C16.9431818,17.1498393 16.1952938,17.8977273 15.2727273,17.8977273 L15.2727273,17.8977273 L12.3222727,17.8969091 L13.1694444,16.5871899 C13.1948404,16.5478294 13.2177553,16.5073784 13.2381831,16.4660493 L15.2727273,16.4659091 C15.4045225,16.4659091 15.5113636,16.359068 15.5113636,16.2272727 L15.5113636,16.2272727 L15.5113636,7.63636364 C15.5113636,7.50456841 15.4045225,7.39772727 15.2727273,7.39772727 L15.2727273,7.39772727 L4.77272727,7.39772727 C4.64093205,7.39772727 4.53409091,7.50456841 4.53409091,7.63636364 L4.53409091,7.63636364 L4.53409091,16.2272727 C4.53409091,16.359068 4.64093205,16.4659091 4.77272727,16.4659091 L4.77272727,16.4659091 L6.93027273,16.4659091 L6.87786904,16.5488572 C6.62755725,16.938389 6.61646758,17.4284405 6.84954875,17.824208 L6.89727273,17.8969091 L4.77272727,17.8977273 C3.89871684,17.8977273 3.18148165,17.2264926 3.10840429,16.3714059 L3.10227273,16.2272727 L3.10227273,7.63636364 C3.10227273,6.71379707 3.8501607,5.96590909 4.77272727,5.96590909 L4.77272727,5.96590909 Z" />
                                    <path d="M11.0914557,12.2335945 C11.2203983,12.2761695 11.2996041,12.3976131 11.2819476,12.5237096 L10.8386686,15.659489 L12.1205095,15.659489 C12.2249567,15.659489 12.3210731,15.712417 12.3716802,15.7975671 C12.4230334,15.8819029 12.421417,15.9858976 12.3674526,16.0695355 L9.38138118,20.6876474 C9.32654639,20.7650036 9.23304126,20.8090909 9.137671,20.8090909 C9.10484473,20.8090909 9.07288885,20.8035073 9.04080863,20.7932707 C8.85650281,20.7312839 8.84184212,20.5773856 8.84672108,20.4374032 L8.87903979,19.9953959 L8.98696858,18.8576187 L9.12685325,17.5021971 L7.9191201,17.4800953 C7.81616498,17.4777688 7.72178946,17.4249571 7.67205269,17.3398071 C7.62231591,17.2553549 7.62480275,17.1521745 7.6780211,17.0693509 L10.7492667,12.3479422 C10.8200173,12.2390618 10.9625131,12.1910195 11.0914557,12.2335945 Z" />
                                    <g transform="translate(5.727273, 8.972727)">
                                        <path d="M0.328643078,1.84609903e-14 L1.31457231,1.84609903e-14 C1.49607687,1.84609903e-14 1.64321539,0.147138518 1.64321539,0.328643078 L1.64321539,1.31457231 C1.64321539,1.49607687 1.49607687,1.64321539 1.31457231,1.64321539 L0.328643078,1.64321539 C0.147138518,1.64321539 8.64911173e-13,1.49607687 8.64911173e-13,1.31457231 L8.64911173e-13,0.328643078 C8.64911173e-13,0.147138518 0.147138518,1.84609903e-14 0.328643078,1.84609903e-14 Z" />
                                        <path d="M2.71640392,1.84609903e-14 L3.70233316,1.84609903e-14 C3.88383772,1.84609903e-14 4.03097624,0.147138518 4.03097624,0.328643078 L4.03097624,1.31457231 C4.03097624,1.49607687 3.88383772,1.64321539 3.70233316,1.64321539 L2.71640392,1.64321539 C2.53489936,1.64321539 2.38776085,1.49607687 2.38776085,1.31457231 L2.38776085,0.328643078 C2.38776085,0.147138518 2.53489936,1.84609903e-14 2.71640392,1.84609903e-14 Z" />
                                        <path d="M5.10416477,1.84609903e-14 L6.09009401,1.84609903e-14 C6.27159857,1.84609903e-14 6.41873709,0.147138518 6.41873709,0.328643078 L6.41873709,1.31457231 C6.41873709,1.49607687 6.27159857,1.64321539 6.09009401,1.64321539 L5.10416477,1.64321539 C4.92266021,1.64321539 4.77552169,1.49607687 4.77552169,1.31457231 L4.77552169,0.328643078 C4.77552169,0.147138518 4.92266021,1.84609903e-14 5.10416477,1.84609903e-14 Z" />
                                        <path d="M7.49192562,1.84609903e-14 L8.47785485,1.84609903e-14 C8.65935941,1.84609903e-14 8.80649793,0.147138518 8.80649793,0.328643078 L8.80649793,1.31457231 C8.80649793,1.49607687 8.65935941,1.64321539 8.47785485,1.64321539 L7.49192562,1.64321539 C7.31042106,1.64321539 7.16328254,1.49607687 7.16328254,1.31457231 L7.16328254,0.328643078 C7.16328254,0.147138518 7.31042106,1.84609903e-14 7.49192562,1.84609903e-14 Z" />
                                    </g>
                                    <path d="M9.62074868,0.0893858514 C9.81659285,-0.00912938653 10.0402545,-0.0262455943 10.2458442,0.0382046706 L10.3666622,0.0866641458 L19.678198,4.68656574 C20.1018944,4.89587189 20.279612,5.41714455 20.0751413,5.85086081 C19.8892588,6.24514833 19.4515092,6.43134004 19.0551252,6.30467969 L18.9377442,6.25719127 L9.99930385,1.84149267 L1.22770987,6.25446957 C0.843866278,6.44755391 0.387083785,6.31783464 0.151317384,5.96792548 L0.0875119012,5.85644965 C-0.101111732,5.46352864 0.0256107096,4.99594383 0.367436139,4.75460185 L0.476336615,4.68928745 L9.62074868,0.0893858514 Z" />
                                </g>
                            </g>
                        </g>
                    </g>
                </g>
            </g>
        </g>
    </svg>
);

export default HouseHold;

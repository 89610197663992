import React from 'react';
import PropTypes from 'prop-types';

export default function ECar({ width, height }) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 22 12">
            <g fill="none" fillRule="evenodd">
                <g fill="#051238" fillRule="nonzero">
                    <g>
                        <g>
                            <g>
                                <path
                                    d="M21.39 7.471c-.47-.919-1.339-1.569-2.353-1.76-.764-.165-1.407-.299-2.063-.392-.566-.083-1.094-.332-1.518-.715-.56-.488-1.17-.918-1.818-1.282-1.24-.655-2.59-1.078-3.983-1.245C9.458.882 8.427.004 7.216 0H5.43c-.246 0-.446.2-.446.447v.527H3.497c-.247 0-.447.2-.447.446 0 .247.2.447.447.447h1.487V3.08H3.497c-.247 0-.447.2-.447.447 0 .246.2.446.447.446h1.487v.527c0 .247.2.447.446.447h1.786c1.173-.001 2.184-.825 2.42-1.974 1.25.156 2.46.537 3.573 1.125.596.335 1.155.73 1.67 1.18.553.494 1.24.815 1.974.92.63.093 1.26.223 2.005.383.756.137 1.404.62 1.75 1.304.187.282.282.614.273.952-.037.495-.454.874-.95.866h-.97v-.045c.001-.605-.239-1.186-.667-1.613-.429-.428-1.01-.667-1.615-.664-1.25.005-2.263 1.017-2.268 2.268v.045H4.555v-.036c.001-.605-.239-1.186-.667-1.613-.429-.428-1.01-.667-1.615-.664-.922 0-1.753.556-2.103 1.409-.35.852-.151 1.832.504 2.48.423.43 1.001.67 1.603.666.916-.009 1.738-.562 2.09-1.407.064.034.134.052.206.054h9.806c.072-.002.143-.02.206-.054.115.278.284.531.495.746.424.427 1.002.665 1.604.66.913-.005 1.736-.555 2.09-1.397.062.036.133.056.205.058h.933c.957.013 1.763-.713 1.85-1.666.03-.513-.1-1.023-.372-1.46zM8.797 2.474c0 .873-.707 1.58-1.58 1.58h-1.34V.895h1.34c.872.002 1.578.708 1.58 1.58zm-6.51 8.57C1.553 11.002.98 10.395.98 9.66s.573-1.34 1.305-1.382c.368-.002.721.142.982.401.26.26.407.612.407.98-.007.762-.625 1.379-1.389 1.384zm14.406 0c-.733-.042-1.306-.649-1.306-1.383s.573-1.34 1.306-1.382c.369-.002.724.143.986.402.396.396.514.99.3 1.508-.214.517-.718.854-1.278.855h-.008z"
                                    transform="translate(-118 -221) translate(96 161) translate(22 55) translate(0 5)"
                                />
                            </g>
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    );
}

export const ECar1 = () => <ECar width="22" height="12" />;
export const ECar2 = () => <ECar width="25" height="14" />;
export const ECar3 = () => <ECar width="29" height="16" />;
export const ECar4 = () => <ECar width="34" height="19" />;
export const ECar5 = () => <ECar width="37" height="21" />;

export const eCars = [<ECar1 />, <ECar2 />, <ECar3 />, <ECar4 />, <ECar5 />];

ECar.propTypes = {
    width: PropTypes.string,
    height: PropTypes.string
};

ECar.defaultProps = {
    width: '22',
    height: '12'
};

import React from 'react';

const NoChargingBattery = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="33" height="28" viewBox="0 0 33 28">
        <g
            fill="none"
            fillRule="evenodd"
            stroke="#051238"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1.572"
        >
            <path d="M16.411 6.385h9.225c1.183 0 2.143.96 2.143 2.145v10.753c0 1.185-.96 2.145-2.143 2.145h-13.44M8.682 21.428H2.93c-1.184 0-2.144-.96-2.144-2.145V8.53c0-1.185.96-2.145 2.144-2.145h10.019M30.118 10.394h.79c.553 0 1.002.448 1.002 1.001v5.023c0 .553-.45 1.001-1.002 1.001h-.79M14.962.786L7.311 27.028" />
        </g>
    </svg>
);

export default NoChargingBattery;

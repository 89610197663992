import React from 'react';

const House2 = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="14" viewBox="0 0 18 14">
        <g fill="#051238">
            <path d="M13.914 5.303c.357 0 .653.267.703.614l.008.107v7.174c0 .362-.263.661-.606.713l-.105.007H4.086c-.357 0-.653-.266-.703-.614l-.008-.106V6.024c0-.398.318-.72.711-.72.357 0 .653.266.703.613l.008.107v6.452h8.405V6.024c0-.362.264-.662.607-.713l.105-.008z" />
            <path d="M8.59.076c.175-.084.375-.098.558-.044l.108.042 8.314 3.904c.378.177.537.62.354.988-.166.335-.557.493-.91.385l-.105-.04-7.981-3.748-7.832 3.746c-.343.163-.75.053-.96-.244l-.058-.094c-.168-.334-.055-.73.25-.935l.097-.056L8.59.076z" />
        </g>
    </svg>
);

export default House2;
